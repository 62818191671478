import React from "react"
import { Link } from "gatsby"

export default ({ children }) => (

<header id="header" class="u-header u-header--bg-transparent u-header--abs-top">

  <div class="u-header__section u-space-1">
    <div id="logoAndNav" class="container">

    <nav class="navbar navbar-expand u-header__navbar">

      <Link to="/" className="navbar-brand u-header__navbar-brand font-size-26 font-weight-bold text-white">
        Techtunes Kaizen
      </Link>
      
      <div class="ml-auto">
        <Link className="btn btn-primary u-btn-primary transition-3d-hover" to="/" target="_blank">Book Now!</Link>
      </div>

    </nav>

    </div>
  </div>

</header>

)
