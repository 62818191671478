import React from "react"

import Head from "../parts/head"
import Header from "../parts/header"
import Footer from "../parts/footer"

export default ({ children }) => (

<html lang="en">

  <Head></Head>

  <body>

    <Header></Header>

    <main id ="content" role="main">
      {children}
    </main>

    <Footer></Footer>

  </body>

</html>

)
