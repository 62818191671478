import React from "react"

export default ({ children }) => (


<footer class="container">
  <div class="row u-space-2">

  <div class="col-md-4 mb-7 mb-md-0">
      <div class="media">
        <div class="d-flex mr-3">
          <span class="u-icon u-icon--lg u-icon-primary--air rounded-circle">
            <span class="fa fa-phone u-icon__inner"></span>
          </span>
        </div>
        <div class="media-body">
          <h4 class="u-list__link font-weight-normal mb-0">Get Us</h4>
          <span class="d-block font-size-14">Techtunes Kaizen</span>
        </div>
      </div>
    </div>

    <div class="col-md-4 mb-7 mb-md-0">
      <div class="media">
        <div class="d-flex mr-3">
          <span class="u-icon u-icon--lg u-icon-primary--air rounded-circle">
            <span class="fa fa-envelope u-icon__inner"></span>
          </span>
        </div>
        <div class="media-body">
          <h4 class="u-list__link font-weight-normal mb-0">Email us</h4>
          <span class="d-block font-size-14">kaizen@kaizen.techtunes.io</span>
        </div>
      </div>
    </div>

    <div class="col-md-4 mb-7 mb-md-0">

      <div class="media">
        <div class="d-flex mr-3">
          <span class="u-icon u-icon--lg u-icon-primary--air rounded-circle">
            <span class="fa fa-map-marker-alt u-icon__inner"></span>
          </span>
        </div>
        <div class="media-body">
          <h4 class="u-list__link font-weight-normal mb-0">Address</h4>
          <span class="d-block font-size-14">Techtunes Plex, Dhaka</span>
        </div>
      </div>

    </div>

  </div>
</footer>

)
