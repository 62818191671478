import React from "react"
import { Helmet } from "react-helmet"

export default ({ children }) => (

  <head>

    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <title>Techtunes Kaizen</title>
      <link rel="shortcut icon" href="favicon.ico" />
      <link rel="canonical" href="https://kizen.techtunes.io" />
    </Helmet>

  </head>

)
