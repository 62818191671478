import React from "react"

export default ({ children }) => (

  <div class="position-relative">
    <div class="container u-space-4-top u-space-3-bottom u-space-5-top--md u-space-4-bottom--md">
      <div class="w-md-80 w-lg-75 text-center mx-auto">
        <h1 class="display-2 font-size-48--md-down text-primary font-weight-bold mb-4">Techtunes Zillion</h1>
        <p class="lead mb-0">Your business envelope design cues consumers into the personality of your company.  Are you hip and trendy?  Elegant and sophisticated? Front design and paper selection says it all!</p>
      </div>
    </div>
  </div>


)
